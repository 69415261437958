import React from "react";
import { navigate } from "@reach/router";

class BackButton extends React.Component {
    render() {
        return (
            <p className="clickable" onClick={this.goBack}>
                <i className="fas fa-arrow-left" /> Back
            </p>
        );
    }

    goBack = () => {
        navigate(-1);
    };
}

export default BackButton;
