import React from "react";
import useSiteMetadata from "../hooks/use-site-metadata";

export default ({ location }) => {
    const { siteUrl } = useSiteMetadata();
    const href = `${siteUrl}${location.pathname}`;
    return (
        <div id="share-buttons">
            <p>Share this post</p>
            <a
                href={`http://www.facebook.com/sharer.php?u=${href}`}
                target="_blank"
            >
                <img
                    src="https://simplesharebuttons.com/images/somacro/facebook.png"
                    alt="Facebook"
                />
            </a>

            <a
                href={`https://twitter.com/share?url=${href}&hashtags=GajGal`}
                target="_blank"
            >
                <img
                    src="https://simplesharebuttons.com/images/somacro/twitter.png"
                    alt="Twitter"
                />
            </a>

            <a href={`mailto:?Subject=GajGal&Body=${href}`} target="_blank">
                <img
                    src="https://simplesharebuttons.com/images/somacro/email.png"
                    alt="Email"
                />
            </a>

            <a
                href={`http://reddit.com/submit?url=${href}&title=GajGal`}
                target="_blank"
            >
                <img
                    src="https://simplesharebuttons.com/images/somacro/reddit.png"
                    alt="Reddit"
                />
            </a>
        </div>
    );
};
