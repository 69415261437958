import React, { useState } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { backend, DEFAULT_FEATURE_IMAGE } from "../constants/backend";
import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import { Row, Col } from "reactstrap";
import ShareButtons from "../components/share-buttons";
import { GET_COMMENTS_OF_POST } from "../constants/queries";
import { auth } from "../utils/auth";
import { INSERT_COMMENT } from "../constants/mutations";
import { isBrowser } from "../utils/helpers";
import LikeButton from "../components/like-button";
import BackButton from "../components/back-button";
import { POST_WAS_LIKED } from "../constants/queries";
import { TOGGLE_LIKE_ON_POST } from "../constants/mutations";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */

class BlogPost extends React.Component {
    constructor(props) {
        super(props);
        this.post = this.props.data.ghostPost;
        this.state = {
            comments: this.props.data.GajCMS.get_comments_of_post,
            auth0_id: undefined,
            isLiked: false,
        };
    }

    componentDidMount() {
        // Add the new comments here
        this.updateComments();
        if (auth.isAuthenticated()) {
            auth.getUser().then((user) =>
                this.setState({ auth0_id: user.sub }, () =>
                    backend
                        .query({
                            query: POST_WAS_LIKED,
                            variables: {
                                auth0_id: this.state.auth0_id,
                                post_id: this.post.id.substr(
                                    "Ghost__Post__".length
                                ),
                            },
                            fetchPolicy: "no-cache",
                        })
                        .then((result) => result.data.post_was_liked)
                        .then((isLiked) => this.setState({ isLiked }))
                )
            );
        }
    }

    updateComments = () => {
        this.getComments(this.props.pageContext.id).then((_comments) => {
            this.state.comments.forEach((comment) => {
                _comments = _comments.filter(
                    (new_comment) => new_comment.id !== comment.id
                );
            });
            this.setState({ comments: _comments.concat(this.state.comments) });
        });
    };

    render() {
        return (
            <>
                <MetaData
                    data={this.data}
                    location={this.props.location}
                    type="article"
                />
                <Helmet>
                    <style type="text/css">{`${this.post.codeinjection_styles}`}</style>
                </Helmet>
                <Layout>
                    <div className="container">
                        <div className="fr-view">
                            <article className="content">
                                <figure className="post-feature-image my-3">
                                    <img
                                        src={
                                            this.post.feature_image
                                                ? this.post.feature_image
                                                : DEFAULT_FEATURE_IMAGE
                                        }
                                        alt={this.post.title}
                                    />
                                </figure>

                                <section className="post-full-content">
                                    {this.renderLikeAndBackRow()}
                                    <h1 className="content-title">
                                        {this.post.title}
                                    </h1>
                                    <h5 className="content-subtitle">
                                        By{" "}
                                        <a
                                            className="content-subtitle"
                                            href={
                                                "/author/" +
                                                this.post.authors[0].slug
                                            }
                                        >
                                            {this.post.authors[0].name}
                                        </a>
                                    </h5>

                                    {/* The main post content */}
                                    <section
                                        className="content-body load-external-scripts"
                                        dangerouslySetInnerHTML={{
                                            __html: this.post.html,
                                        }}
                                    />

                                    {/* Share buttons */}
                                    <ShareButtons
                                        location={this.props.location}
                                    />
                                    {this.renderLikeAndBackRow()}

                                    <Row className="my-5">
                                        <Col className="col-12">
                                            <p className="mx-auto my-2">
                                                <strong>Comments</strong>
                                            </p>
                                            <CommentInputForm
                                                auth0_id={this.state.auth0_id}
                                                post_id={this.post.id.substr(
                                                    "Ghost__Post__".length
                                                )}
                                                comments={this.state.comments}
                                            />
                                            <div
                                                style={{
                                                    borderTop:
                                                        "1px solid var(--gaj-dark-purple)",
                                                }}
                                                className="w-100 mx-auto my-4"
                                            />
                                            {this.state.comments.map((node) => (
                                                <React.Fragment>
                                                    <p>
                                                        <strong className="text-secondary">
                                                            Lenny Parisi
                                                        </strong>{" "}
                                                        <small className="text-muted">
                                                            {new Date(
                                                                Number(
                                                                    node.date_posted
                                                                )
                                                            ).toLocaleString()}
                                                        </small>
                                                    </p>

                                                    <small>
                                                        {node.comment}
                                                    </small>
                                                </React.Fragment>
                                            ))}
                                        </Col>
                                    </Row>
                                </section>
                            </article>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }

    renderLikeAndBackRow = () => {
        return (
            <Row>
                <Col>
                    <BackButton />
                </Col>
                <Col className="text-right">
                    {this.state.auth0_id != undefined && (
                        <LikeButton
                            onChange={this.toggleLike}
                            isLiked={this.state.isLiked}
                        />
                    )}
                </Col>
            </Row>
        );
    };

    // Toggles the like on this post
    toggleLike = () => {
        backend
            .mutate({
                mutation: TOGGLE_LIKE_ON_POST,
                variables: {
                    auth0_id: this.state.auth0_id,
                    post_id: this.post.id.substr("Ghost__Post__".length),
                },
            })
            .then((result) => result.data.toggle_like_on_post)
            .then((isLiked) => this.setState({ isLiked }));
    };

    // Get comments attached to post
    getComments = (post_id) => {
        return backend
            .query({
                query: GET_COMMENTS_OF_POST,
                variables: {
                    post_id,
                },
            })
            .then((results) => results.data.get_comments_of_post);
    };
}

export default BlogPost;

export const postQuery = graphql`
    query($slug: String!, $id: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        GajCMS {
            get_comments_of_post(post_id: $id) {
                id
                comment
                date_posted
            }
        }
    }
`;

// Helpers Components
function CommentInputForm({ post_id, auth0_id }) {
    const [comment, setComment] = useState("");
    return (
        <React.Fragment>
            {auth0_id != undefined && (
                <React.Fragment>
                    <textarea
                        className="w-100 form-control form-control-lg"
                        placeholder="Add a comment"
                        onInput={(e) => {
                            setComment(e.currentTarget.value);
                        }}
                        id="comment-input"
                    />
                    <button
                        className="btn btn-primary btn-lg my-2"
                        onClick={(e) => {
                            e.preventDefault();
                            if (comment != "") {
                                submitComment(
                                    post_id,
                                    auth0_id,
                                    comment,
                                    setComment
                                );
                            }
                        }}
                    >
                        Submit
                    </button>
                </React.Fragment>
            )}
            {auth0_id == undefined && (
                <small>
                    Please{" "}
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            auth.login();
                        }}
                    >
                        login or create an account
                    </a>{" "}
                    to comment.
                </small>
            )}
        </React.Fragment>
    );
}

// Helper Functions

// Submit the comment
function submitComment(post_id, auth0_id, comment, setComment) {
    backend
        .mutate({
            mutation: INSERT_COMMENT,
            variables: {
                post_id,
                auth0_id,
                comment,
            },
        })
        .then(() => {
            document.getElementById("comment-input").value = "";
            setComment("");
            if (isBrowser) {
                window.location.reload();
            }
        });
}
