import React from "react";

class LikeButton extends React.Component {
    render() {
        return (
            <p className="clickable" onClick={this.props.onChange}>
                <i
                    className={
                        this.props.isLiked ? "fas fa-heart" : "far fa-heart"
                    }
                />
                {"  "}
                {this.props.isLiked ? "Unlike this post  " : "Like this post  "}
            </p>
        );
    }
}

export default LikeButton;
